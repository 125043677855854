
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateWrapper = ({ children, allowViewers }) => {
  const isAuthenticated = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');
  
  const isAllowed = allowViewers ? true : userType === 'customUser';

  return isAuthenticated && isAllowed ? children : <Navigate to="/login" />;
};

export default PrivateWrapper;
