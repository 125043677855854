import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WebinarsList from "../components/WebinarsList";
import Pagination from "../components/Pagination";

export default function WebinarsPage() {
  const [webinars, setWebinars] = useState([]);
  const [userData, setUserData] = useState(() => {
    const storedUserData = localStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : {};
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const webinarsPerPage = 4; 
  const [totalWebinars, setTotalWebinars] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const canCreateWebinar = userData.webinarsCreated < userData.maxWebinars;

  const refreshUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("/api/currentUser", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.message && data.message === "Токен недействителен или истек") {
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        localStorage.setItem("userData", JSON.stringify(data));
        setUserData(data);
      }
    } catch (error) {
      console.error("Ошибка при обновлении данных пользователя:", error);
    }
  };

  const fetchWebinars = async () => {
    const url = `/api/webinars?page=${currentPage}&limit=${webinarsPerPage}&search=${encodeURIComponent(searchQuery)}`;
    console.log('Отправка запроса на сервер: ', url); 
    try {
      const response = await fetch(url);
      const data = await response.json();
      setWebinars(data.webinars);
      setTotalWebinars(data.totalWebinars);
    } catch (error) {
      console.error("Ошибка при загрузке вебинаров:", error);
    }
  };

  const handleSearch = async () => {
    console.log('Поисковой запрос:', searchQuery);
    fetchWebinars();
  };

  useEffect(() => {
    refreshUserData();
    fetchWebinars();
  }, [currentPage]);

  const deleteWebinarFromList = async (webinarId) => {
    try {
      const response = await fetch(`/api/webinars/${webinarId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        const updatedWebinars = webinars.filter(webinar => webinar._id !== webinarId);
        setWebinars(updatedWebinars);
        setTotalWebinars(prev => prev - 1);

        if (updatedWebinars.length === 0 && currentPage > 1) {
          setCurrentPage(prev => prev - 1);
        } else {
          fetchWebinars();
        }

        await refreshUserData();
      } else {
        throw new Error(data.message || 'Ошибка при удалении вебинара');
      }
    } catch (error) {
      console.error("Ошибка при удалении вебинара:", error);
    }
  };
  
  const totalPages = Math.ceil(totalWebinars / webinarsPerPage);

  return (
    <div>
      <WebinarsList
        webinars={webinars}
        canCreateWebinar={canCreateWebinar}
        navigate={navigate}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSearch={handleSearch}
        deleteWebinarFromList={deleteWebinarFromList}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
