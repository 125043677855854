import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

function ViewerLoginPage() {

    const handleLogin = () => {
        const webinarUrl = window.location.href; 
        localStorage.setItem('viewerRedirect', webinarUrl);
        const redirectUrl = `/api/auth/google?redirect=${encodeURIComponent(webinarUrl)}`;
        window.location.href = redirectUrl;
    };

    const handleFacebookLogin = () => {
        const webinarUrl = window.location.href;
        localStorage.setItem('viewerRedirect', webinarUrl);
        const redirectUrl = `/api/auth/facebook?redirect=${encodeURIComponent(webinarUrl)}`;
        window.location.href = redirectUrl;
      };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
      
        if (token) {
          
          const decodedToken = jwtDecode(token);
          localStorage.setItem('currentUser', JSON.stringify(decodedToken));
          localStorage.setItem('viewerToken', token);
          localStorage.setItem("userType", "viewer");

          const redirectUrl = localStorage.getItem('viewerRedirect') || '/';
          localStorage.removeItem('viewerRedirect');
          window.location.href = redirectUrl;
        }
    }, []);
      
    return (
        <div>
            <h1>Вход для зрителей</h1>
            <button onClick={handleLogin}>Войти через Google</button>
            <button onClick={handleFacebookLogin}>Войти через Facebook</button>
        </div>
    );
}

export default ViewerLoginPage;
