import React, { useState } from "react";

import Pagination from "../Pagination";
import WebinarCard from "../WebinarCard";

import "./index.scss";
import LanguageSwitcher from "../LanguageSwitcher";
import LogoutButton from "../LogoutButton";

function WebinarsList({
  webinars,
  canCreateWebinar,
  navigate,
  searchQuery,
  setSearchQuery,
  handleSearch,
  deleteWebinarFromList,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const toggleSearchVisibility = () => {
    // Если поиск уже виден и есть поисковый запрос, выполняем поиск
    if (isSearchVisible && searchQuery) {
      handleSearch();
    }
    // Переключаем видимость поиска
    setIsSearchVisible(!isSearchVisible);
  };

  const onSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      setIsSearchVisible(false); // Скрываем поле поиска после выполнения поиска
    }
  };

  const handleSearchClick = () => {
    if (!isSearchVisible) {
      setIsSearchVisible(true);
    } else {
      handleSearch();
      setIsSearchVisible(false);
    }
  };

  return (
    <>
      <div className="webinars__header">
        <div className="webinars__header_content">
          <h1 className="webinars__title">Список вебинаров</h1>
          <h1 className={`webinars__title_mobile ${isSearchVisible ? 'hidden' : ''}`}>Вебинары</h1>
          <div className={`webinars__header_panel ${isSearchVisible ? 'end' : ''}`}>
            <div className="webinars__search_container">
              <input
                className="webinars__search_input"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />

              <input
                className={`webinars__search_input mobile ${
                  isSearchVisible ? "visible" : ""
                }`}
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />

              <button
                className="webinars__search_btn"
                onClick={handleSearchClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M8.75008 15.8333C12.6621 15.8333 15.8334 12.662 15.8334 8.74996C15.8334 4.83796 12.6621 1.66663 8.75008 1.66663C4.83808 1.66663 1.66675 4.83796 1.66675 8.74996C1.66675 12.662 4.83808 15.8333 8.75008 15.8333Z"
                    stroke="#0D639C"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.8423 13.8424L17.3778 17.3779"
                    stroke="#0D639C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <LanguageSwitcher className={"black"} />
            <LogoutButton />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="webinars__list">
          {webinars.map((webinar) => (
            <WebinarCard
              key={webinar._id}
              webinar={webinar}
              onDelete={deleteWebinarFromList}
            />
          ))}
          <div>
            {canCreateWebinar ? (
              <button
                className="webinars__card webinars__card_create"
                onClick={() => navigate("/create-webinar")}
              >
                <span>+</span>
                <p> Создать новый вебинар</p>
              </button>
            ) : (
              <div className="webinars__card webinars__card_disable">
                <span>+</span>
                <p> Вы превысили лимит допустимого количества вебинаров</p>
              </div>
            )}
          </div>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
}

export default WebinarsList;
