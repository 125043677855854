import LanguageSwitcher from '../components/LanguageSwitcher';
import LoginForm from '../components/LoginForm';



function LoginPage() {
    return<div className='login__page'>
    <div className="login__language_switcher">
     <LanguageSwitcher className={"white"} />
     </div>
     <LoginForm/>
    </div>
}

export default LoginPage;
