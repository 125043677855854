import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import WebinarForm from "../components/WebinarForm";
import ChatComponent from "../components/ChatComponent";
import LanguageSwitcher from "../components/LanguageSwitcher";
import LogoutButton from "../components/LogoutButton";
import "../styles/CreateWebinarPage.scss";

export default function CreateWebinarPage() {
  const navigate = useNavigate();
  const [webinarData, setWebinarData] = useState(null);
  const [webinarOptions, setWebinarOptions] = useState(null);
  const [file, setFile] = useState(null);
  const [isWebinarStarted, setIsWebinarStarted] = useState(false);
  const [webinarLinks, setWebinarLinks] = useState({
    webinarLink: "",
    chatLink: "",
  });
  const [previewImage, setPreviewImage] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");

  if (userData.webinarsCreated >= userData.maxWebinars) {
    navigate("/webinars");
  }

  const handleWebinarFormSubmit = (webinarData, webinarOptions, file) => {
    
    if (!webinarOptions.recordWebinar) {
      delete webinarOptions.recordExpiryDate;
    }
    const webinarId = webinarData._id || uuidv4();
    const webinarLink = `/api/webinar/${webinarId}`;
    const chatLink = `/api/chat/${webinarId}`;

    
    setWebinarData(webinarData);
    setWebinarOptions(webinarOptions);
    setFile(file);

    
    saveWebinar();
  };

  const saveWebinar = async () => {
    if (!webinarData || !webinarOptions || !file) {
      return;
    }

    const { title, description } = webinarData;
    const webinarId = uuidv4();
    const webinarLink = `/api/webinar/${webinarId}`;
    const chatLink = `/api/chat/${webinarId}`;
    setWebinarLinks({ webinarLink, chatLink });

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("webinarUrl", webinarLink);
    formData.append("chatUrl", chatLink);
    formData.append("recordWebinar", webinarOptions.recordWebinar);
    formData.append("recordExpiryDate", webinarOptions.recordExpiryDate);
    formData.append("webinarImage", file);

    try {
      const token = localStorage.getItem("token");
      const response = await fetch("/api/webinars", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.webinar) {
        navigate("/webinars");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  const goBack = () => {
    navigate("/webinars");
  };

  return (
    <div>
      <div className="header">
        <div className=" header__wrap">
          <div className=" header_nav">
            <button onClick={goBack} className=" header_back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
              >
                <path
                  d="M7.75 1.875L1.73599 7.38785C1.30362 7.78418 1.30362 8.46582 1.73599 8.86215L7.75 14.375"
                  stroke="#0D639C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <h1 className=" header_title">Создать новый вебинар</h1>
            <h1 className=" header_title_mobile">Вебинары</h1>
          </div>
          <div className=" header_panel">
            <LanguageSwitcher className={"black"} />
            <LogoutButton />
          </div>
        </div>
      </div>
      <WebinarForm
        onSubmit={handleWebinarFormSubmit}
        onSave={saveWebinar}
        isWebinarStarted={isWebinarStarted}
        setIsWebinarStarted={setIsWebinarStarted}
        webinarLinks={webinarLinks}
        setWebinarLinks={setWebinarLinks}
        onFileUpload={(file) => setPreviewImage(URL.createObjectURL(file))}
      />

      {previewImage ? (
        <div className="preview__container">
          <div className="preview__img" style={{ marginTop: "20px" }}>
            <img
              src={previewImage}
              alt="Webinar Preview"
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="preview__chat">
            <ChatComponent
              className="chat-preview"
              chatId="preview"
              isPreview={true}
            />
          </div>
        </div>
      ) : (
        <div div className="preview__container">
          <div className="preview__image"></div>
          <div className="preview__chat">
            <h2 className="preview__chat_title">Chat</h2>

            <div className="preview__chat_send">
              <input
                className="preview__chat_input"
                type="text"
                disabled={true}
                placeholder="Введите ваше сообщение"
              />
              <button className="preview__chat_btn" disabled={true}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M2.25 15V10.5L8.25 9L2.25 7.5V3L16.5 9L2.25 15Z"
                    fill="#F2F2F2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
