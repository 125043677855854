import React, { useState } from "react";
import "./index.scss";

const CollapsibleBlock = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="collapsible__wrapper">
      <div className="collapsible" onClick={() => setIsOpen(!isOpen)}>
        <h1 className="collapsible__title">Тема вебинара</h1>
        <div className={`collapsible__btn ${!isOpen ? "is-closed" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
          >
            <path
              d="M22 12L12.7372 1.89508C12.3408 1.46271 11.6592 1.46271 11.2628 1.89508L2 12"
              stroke="#0D639C"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};

export default CollapsibleBlock;
