import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateWrapper from "./components/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import WebinarsPage from "./pages/WebinarsPage";
import HomePage from "./pages/HomePage";
import LanguageSwitcher from "./components/LanguageSwitcher";
import 'sweetalert2/src/sweetalert2.scss';
import "./styles/App.scss";
import CreateWebinarPage from "./pages/CreateWebinarPage";
import WebinarPage from "./pages/WebinarPage";
import ChatPage from "./pages/ChatPage";
import WebinarSettingsPage from "./pages/WebinarSettingsPage";
import LogoutButton from "./components/LogoutButton";
import ViewerLoginPage from "./pages/ViewerLoginPage";


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/webinars" element={<PrivateWrapper allowViewers={false}><WebinarsPage /></PrivateWrapper>} />
<Route path="/create-webinar" element={<PrivateWrapper allowViewers={false}><CreateWebinarPage /></PrivateWrapper>} />
<Route path="/webinars/:webinarId/settings" element={<PrivateWrapper allowViewers={false}><WebinarSettingsPage /></PrivateWrapper>} />
<Route path="/webinar/:webinarId" element={<WebinarPage />} />
          <Route path="/viewer-login" element={<ViewerLoginPage />} />
          <Route path="/chat/:chatId" element={<ChatPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
