import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import io from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import "./index.scss";
import LanguageSwitcher from "../LanguageSwitcher";

const socket = io(window.location.origin);

const ChatComponent = ({ chatId, isPreview, className }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);
  const currentUserData = JSON.parse(localStorage.getItem("currentUser")) || {};
  const currentViewerToken = localStorage.getItem("viewerToken");
  let decodedToken = {};
  if (currentViewerToken) {
    try {
      decodedToken = jwtDecode(currentViewerToken);
    } catch (error) {
      console.error("Ошибка декодирования токена:", error);
    }
  }
  const currentUserEmail =
    decodedToken.email || currentUserData.email || "default@example.com";

  useLayoutEffect(() => {
    const chatContainer = document.querySelector(".chat__container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (isPreview) {
      setMessages([
        {
          sender: {
            email: "other@example.com",
            name: "Диана",
            surname: "Абдулина",
          },
          text: "Varius facilisi laoreet leo pharetra cras. Pharetra sem massa quis pellentesque.",
          timestamp: "14:00",
        },
        {
          sender: { email: currentUserEmail, name: "Вы" },
          text: "Mattis commodo, neque mus mauris sit mi",
          timestamp: "",
        },
        {
          sender: { email: currentUserEmail, name: "Вы" },
          text: "Mattis commodo, neque mus mauris sit mi",
          timestamp: "",
        },
        {
          sender: { email: currentUserEmail, name: "Вы" },
          text: "Mattis commodo, neque mus mauris sit mi",
          timestamp: "",
        },
        {
          sender: {
            email: "other@example.com",
            name: "Олег",
            surname: "Иванов",
          },
          text: "Varius facilisi",
          timestamp: "13:45",
        },
        {
          sender: { email: currentUserEmail, name: "Вы" },
          text: "Lorem ipsum dolor sit amet",
          timestamp: "",
        },
      ]);
      return;
    }

    socket.emit("join room", chatId);

    const handleRecentMessages = (recentMessages) => {
      setMessages(recentMessages);
    };

    const handleNewMessage = (msg) => {
      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (m) =>
            m.sender.email === msg.sender.email &&
            m.text === msg.text &&
            m.timestamp === msg.timestamp
        );
        return isDuplicate ? prevMessages : [...prevMessages, msg];
      });
    };

    socket.on("recent messages", handleRecentMessages);
    socket.on("chat message", handleNewMessage);

    return () => {
      socket.off("recent messages", handleRecentMessages);
      socket.off("chat message", handleNewMessage);
    };
  }, [chatId, isPreview]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const sendMessage = () => {
    if (input.trim() === "") return;

    const senderName =
      decodedToken.firstName || currentUserData.firstName || currentUserEmail;
    const senderSurname =
      decodedToken.lastName || currentUserData.lastName || "";

    const newMessage = {
      text: input,
      sender: {
        email: currentUserEmail,
        name: senderName,
        surname: senderSurname,
      },
      timestamp: new Date().toISOString(),
    };

    socket.emit("chat message", newMessage, chatId);
    setInput("");
  };

  const displayName = (sender) => {
    return sender.email === currentUserEmail
      ? "Вы"
      : `${sender.name} ${sender.surname}`;
  };
  const isCurrentUser = (email) => {
    return email === currentUserEmail;
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}`;
  }
  

  return (
    <div className={`chat ${className}`}>
      <div className="language__switcher">
        <LanguageSwitcher />
      </div>
      <h2 className="chat__title">Чат</h2>
      <div className="chat__container">
        {messages.map((message, index) => (
          <div className="chat__message_wrap">
            <div className="chat__title_wrap">
              {message.sender.email !== currentUserEmail ? (
                <strong className="chat__author">
                  {displayName(message.sender)}
                </strong>
              ) : (
                <div></div>
              )}

              {message.sender.email !== currentUserEmail ? (
                <span className="chat__time">{formatTimestamp(message.timestamp)}</span>
              ) : (
                <strong className="chat__author">
                  {displayName(message.sender)}
                </strong>
              )}
            </div>
            <div
              key={index}
              className={`chat__message ${
                message.sender.email === currentUserEmail ? "current-user" : ""
              }`}
            >
              <span className="chat__message_text">{message.text}</span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat__send_container">
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Введите ваше сообщение"
          className="chat__send_input"
          disabled={isPreview}
        />
        <button className="chat__send_btn" onClick={sendMessage}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M2.25 15V10.5L8.25 9L2.25 7.5V3L16.5 9L2.25 15Z"
              fill="#F2F2F2"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
