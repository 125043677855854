import { Link } from "react-router-dom";




export default function HomePage(){
    return(
        <div>Home
            <Link to="/login">Login</Link>
        </div>
    )
}
