import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import i18n from '../../i18n'; 
import "./index.scss";

const LanguageSwitcher = ({className}) => {
  
  const [language, setLanguage] = useLocalStorage('language', 'ru');

  const changeLanguage = (event) => {
    const newLang = event.target.value;
    setLanguage(newLang);
    i18n.changeLanguage(newLang);  
  };

  return (
    <select className={className} value={language} onChange={changeLanguage}>
      <option value="ru">RU</option>
      <option value="ua">UA</option>
      <option value="en">EN</option>
    </select>
  );
};

export default LanguageSwitcher;