import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import "./index.scss";

const WebinarCard = ({ webinar, onDelete }) => {
  const { t } = useTranslation();
  const handleDelete = async () => {
    const result = await Swal.fire({
      title: t("Уверены что хотите удалить вебинар?"),
      html: `<strong style="color: #000; font-size: 14px; font-family: Inter; font-weight: 400;">${webinar.title}</strong>`, 
      showCancelButton: true,
      confirmButtonText: `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
      <path d="M11.2313 5.03333V5.03338L11.2378 5.03329C11.2475 5.03317 11.2571 5.03517 11.266 5.03917L11.4714 4.58328L11.266 5.03917C11.2749 5.04316 11.2828 5.04905 11.2891 5.05642C11.2955 5.0638 11.3002 5.07246 11.3028 5.08182L11.784 4.94589L11.3028 5.08183C11.3054 5.09098 11.306 5.10057 11.3046 5.10997C11.3045 5.11019 11.3045 5.11041 11.3044 5.11063L9.78713 14.1692C9.78403 14.1838 9.77614 14.1969 9.7647 14.2065C9.75291 14.2163 9.73806 14.2218 9.72268 14.222H2.74399C2.72861 14.2218 2.71376 14.2163 2.70196 14.2065C2.69053 14.1969 2.68264 14.1838 2.67954 14.1692L1.16222 5.11065C1.16219 5.11044 1.16215 5.11022 1.16212 5.11C1.16071 5.10071 1.16128 5.09123 1.16379 5.08218C1.16636 5.07291 1.1709 5.06431 1.1771 5.05696C1.18331 5.04962 1.19103 5.0437 1.19974 5.03962C1.20817 5.03567 1.21734 5.03353 1.22664 5.03333H11.2313ZM3.4 2.76667H3.9V2.26667V0.566667C3.9 0.548985 3.90702 0.532028 3.91953 0.519526C3.93203 0.507024 3.94899 0.5 3.96667 0.5H8.5C8.51768 0.5 8.53464 0.507025 8.54714 0.519526C8.55964 0.532027 8.56667 0.548983 8.56667 0.566667V2.26667V2.76667H9.06667H11.9C11.9177 2.76667 11.9346 2.77369 11.9471 2.78619C11.9596 2.79869 11.9667 2.81565 11.9667 2.83333C11.9667 2.85102 11.9596 2.86797 11.9471 2.88047C11.9346 2.89298 11.9177 2.9 11.9 2.9H0.566667C0.548986 2.9 0.532029 2.89298 0.519526 2.88047C0.507024 2.86797 0.5 2.85101 0.5 2.83333C0.5 2.81565 0.507024 2.7987 0.519526 2.78619C0.532029 2.77369 0.548986 2.76667 0.566667 2.76667H3.4ZM7.93333 2.76667H8.43333V2.26667V1.13333V0.633333H7.93333H4.53333H4.03333V1.13333V2.26667V2.76667H4.53333H7.93333Z" stroke="#E30E00"/>
      </svg> ${t("Yes")}`,
      cancelButtonText: t("No"),
      focusConfirm: false,
      customClass: {
        title: 'modal__title',
        confirmButton: 'delete__webinar', 
        cancelButton: 'cancel__webinar'
      },
      buttonsStyling: false, 
    });
  
    if (result.isConfirmed) {
      onDelete(webinar._id);
    }
  };

  return (
    <div className="webinar__card">
      <Link   to={`/webinar/${webinar._id}`}>
        <img
          className="webinar__card_img"
          src={`/uploads/${webinar.imageUrl.split("/").pop()}`} 
          alt={webinar.title}
        />
      </Link>
      <Link  to={`/webinar/${webinar._id}`}>
        <h2 className="webinar__card_title">{webinar.title}</h2>
      </Link>
      <div className="webinar__card_links">
        <button className="webinar__card_login">
        <Link to={`/webinars/${webinar._id}/settings`}>{t("loginToTheWebinar")}</Link>
        </button>
        <button className="webinar__card_delete" onClick={handleDelete}>
          {t("delete")}
        </button>
      </div>
    </div>
  );
};

export default WebinarCard;
