import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ChatComponent from '../components/ChatComponent';

function ChatPage() {
  const { chatId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    
    if (token) {
      localStorage.setItem('viewerToken', token);
      const newUrl = window.location.href.split('?')[0];
      window.history.replaceState({}, null, newUrl);
    }

    const isUserAuthenticated = Boolean(localStorage.getItem('viewerToken') || localStorage.getItem('token'));
    const chatUrl = window.location.href;
    
    if (!isUserAuthenticated) {
      Swal.fire({
        title: 'Вход для зрителей',
        text: 'Пожалуйста, войдите, чтобы просмотреть чат вебинара',
        confirmButtonText: 'Войти через Google',
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem('viewerRedirect', chatUrl);
          window.location.href = `/api/auth/google?redirect=${encodeURIComponent(chatUrl)}`;
        }
      });
    }
  }, [chatId]);

  return (
    <div className='chat__page'>
      
      <ChatComponent className="chat-chat" chatId={chatId} />
    </div>
  );
}

export default ChatPage;
