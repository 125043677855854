import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ChatComponent from "../components/ChatComponent";
import { jwtDecode } from "jwt-decode";

import "../styles/WebinarPage.scss";
import LanguageSwitcher from "../components/LanguageSwitcher";

function WebinarPage() {
  const [webinar, setWebinar] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState(null);
  const { webinarId } = useParams();

  useEffect(() => {
    const fetchWebinar = async () => {
      try {
        const response = await fetch(`/api/webinars/${webinarId}`);
        const data = await response.json();
        setWebinar(data);
      } catch (error) {
        console.error("Error fetching webinar data:", error);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("viewerToken", token);
      const newUrl = window.location.href.split("?")[0];
      window.history.replaceState({}, null, newUrl);
    }

    const isUserAuthenticated = Boolean(
      localStorage.getItem("viewerToken") || localStorage.getItem("token")
    );
    const webinarUrl = window.location.href;
    const viewerToken = localStorage.getItem("viewerToken");
    if (viewerToken) {
      try {
        const decodedToken = jwtDecode(viewerToken);
        setCurrentUserEmail(decodedToken.email);
      } catch (error) {
        console.error("Error decoding the token:", error);
      }
    }
    if (!isUserAuthenticated) {
      Swal.fire({
        title: "Вход для зрителей",
        text: "Пожалуйста, войдите, чтобы просмотреть вебинар",
        confirmButtonText: "Войти через Google",
        cancelButtonText: "Войти через Facebook",
        showCancelButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("viewerRedirect", webinarUrl);
          window.location.href = `/api/auth/google?redirect=${encodeURIComponent(
            webinarUrl
          )}`;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          localStorage.setItem("viewerRedirect", webinarUrl);
          window.location.href = `/api/auth/facebook?redirect=${encodeURIComponent(
            webinarUrl
          )}`;
        }
      });
    } else {
      fetchWebinar();
    }
  }, [webinarId]);

  if (!webinar) {
    return <div>Loading...</div>;
  }

  return (
    <div className="webinar">
    <div className="webinar__page">
     
      <div className="webinar__image_wrap">
        <div className="webinar__language_switcher">
      <LanguageSwitcher className={"black"}  />
      </div>

        <h1 className="webinar__title">{webinar.title}</h1>

        {webinar.imageUrl && (
          <div className="webinar__image_container">
            <img src={`/uploads/${webinar.imageUrl.split("/").pop()}`} alt="Webinar" />
          </div>
        )}
        <p className="webinar__description">{webinar.description}</p>
      </div>
      <ChatComponent
        chatId={webinarId}
        className="chat-preview webinar"
        currentUserEmail={currentUserEmail}
      />
    </div>
    </div>
  );
}

export default WebinarPage;
