import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import "./index.scss";

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch("/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Успешный вход, токен:", data.accessToken);

      const decodedToken = jwtDecode(data.accessToken);

      localStorage.setItem("currentUser", JSON.stringify(decodedToken));
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("email", data.email);
      localStorage.setItem("id", data._id);
      localStorage.setItem("role", data.role);
      localStorage.setItem("userType", "customUser");

      if (localStorage.getItem("token")) {
        navigate("/webinars");
      }
    } else {
      const errorData = await response.json();
      console.error("Ошибка входа:", errorData.message);
      setError("Неверный логин и пароль");
    }
  };

  const inputClass = error ? "input-error" : "";

  return (
    <div className="login">
      <form className="login__form" onSubmit={handleSubmit}>
        <div className="login__logo">LOGO</div>
        <div className="login__input_wrapper">
          <p>{t("email")}</p>
          <input
            className={`login__input ${inputClass}`}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="tim.jennings@example.com"
            required
          />
        </div>
        <div className="login__input_wrapper">
          <p>{t("password")}</p>
          <input
            className={`login__input ${inputClass}`}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="••••••••"
            required
          />
        </div>
        {error && (
          <div className="error-message">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_1033_1078)">
                <path
                  d="M9 6.75V8.25M9 11.25V11.2575"
                  stroke="#E30E00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.7499 14.25H14.2499C14.4946 14.2483 14.7352 14.1867 14.9507 14.0707C15.1662 13.9546 15.35 13.7876 15.4861 13.5842C15.6222 13.3808 15.7064 13.1472 15.7315 12.9037C15.7566 12.6603 15.7217 12.4144 15.6299 12.1875L10.3049 3.00001C10.1752 2.76556 9.98503 2.57013 9.75421 2.43405C9.5234 2.29797 9.26034 2.2262 8.9924 2.2262C8.72445 2.2262 8.4614 2.29797 8.23058 2.43405C7.99977 2.57013 7.80962 2.76556 7.6799 3.00001L2.3549 12.1875C2.26485 12.4092 2.22915 12.6492 2.25076 12.8875C2.27237 13.1258 2.35068 13.3555 2.47914 13.5573C2.6076 13.7592 2.78248 13.9274 2.98919 14.0479C3.1959 14.1684 3.42844 14.2377 3.6674 14.25"
                  stroke="#E30E00"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1033_1078">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {error}
          </div>
        )}{" "}
        <button className="login__button" type="submit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_1002_693)">
              <circle cx="12" cy="12" r="12" fill="white" />
              <path
                d="M6 19V18C6 15.7909 7.79086 14 10 14H14C16.2091 14 18 15.7909 18 18V19"
                fill="#0D639C"
              />
              <path
                d="M12 11C10.3431 11 9 9.65685 9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11Z"
                stroke="#0D639C"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1002_693">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {t("signIn")}
        </button>
        <button className="login__tvpanel_button">Войти через TV-Panel</button>
      </form>
    </div>
  );
}
