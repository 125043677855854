import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import WebinarForm from "../components/WebinarForm";
import ChatComponent from "../components/ChatComponent";
import "../styles/CreateWebinarPage.scss"
import LanguageSwitcher from "../components/LanguageSwitcher";
import LogoutButton from "../components/LogoutButton";


export default function WebinarSettingsPage() {
  const [webinar, setWebinar] = useState(null);
  const [isWebinarStarted, setIsWebinarStarted] = useState(false);
  const { webinarId } = useParams();
  
  const [previewImage, setPreviewImage] = useState(null);
  const currentUserData = JSON.parse(localStorage.getItem("currentUser")) || {};
  const currentUserEmail = currentUserData.email || "default@example.com";
  const navigate = useNavigate();
  useEffect(() => {
    const fetchWebinar = async () => {
      try {
        console.log("Fetching webinar with ID:", webinarId);
        const response = await fetch(
          `/api/webinars/${webinarId}`
        );
        const data = await response.json();
        console.log("Webinar data received:", data);
        setWebinar(data);

        if (data.imageUrl) {
          setPreviewImage(data.imageUrl);
        }
      } catch (error) {
        console.error("Error while fetching webinar:", error);
      }
    };

    if (webinarId) {
      fetchWebinar();
    }
  }, [webinarId]);

  const handleSubmit = async (webinarData) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`/api/webinars/${webinarId}/title`, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ title: webinarData.title })
      });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok, status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Webinar title updated successfully", data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };
  
  

  const goBack = () => {
    navigate("/webinars");
  };

  return (
    <div className="webinar__setting">
     <div className="header">
        <div className=" header__wrap">
          <div className=" header_nav">
            <button onClick={goBack} className=" header_back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
              >
                <path
                  d="M7.75 1.875L1.73599 7.38785C1.30362 7.78418 1.30362 8.46582 1.73599 8.86215L7.75 14.375"
                  stroke="#0D639C"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <h1 className=" header_title">Настройки вебинара</h1>
            <h1 className=" header_title_mobile">Вебинары</h1>
          </div>
          <div className=" header_panel">
            <LanguageSwitcher className={"black"} />
            <LogoutButton />
          </div>
        </div>
      </div>
      {webinar ? (
        <WebinarForm
          onSubmit={handleSubmit}
          initialData={webinar}
          setIsWebinarStarted={setIsWebinarStarted}
          isWebinarStarted={isWebinarStarted}
          isSettingsPage={true}
        />
      ) : (
        <p>Loading...</p>
      )}

{previewImage && (
        <div className="preview__container">
          
          <div className="preview__img">
            <img
              src={previewImage}
              alt="Webinar Preview"
            />
          </div>
          
          <div className="preview__chat">
            <ChatComponent
              className="chat-preview"
              chatId="preview"
              isPreview={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
