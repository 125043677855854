import "./index.scss"

export default function LogoutButton() {
    const handleLogout = () => {
        
        localStorage.removeItem('token');
        localStorage.removeItem('viewerToken'); 
        localStorage.removeItem('email');
        localStorage.removeItem('role');
        localStorage.removeItem('userData');
        localStorage.removeItem('id');
        
        window.location.href = '/';
    };
    
    return (
        <button className="account__btn" onClick={handleLogout}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <g clip-path="url(#clip0_1008_1057)">
        <circle cx="15" cy="15" r="15" fill="#0D639C"/>
        <path d="M7.5 23.75V22.5C7.5 19.7386 9.73857 17.5 12.5 17.5H17.5C20.2614 17.5 22.5 19.7386 22.5 22.5V23.75" fill="white"/>
        <path d="M15 13.75C12.9289 13.75 11.25 12.0711 11.25 10C11.25 7.92894 12.9289 6.25 15 6.25C17.0711 6.25 18.75 7.92894 18.75 10C18.75 12.0711 17.0711 13.75 15 13.75Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_1008_1057">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
        </defs>
        </svg></button>
    );
}
