
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';
import ua from './locales/ua/translation.json';

const getInitialLanguage = () => {
    try {
        const languageFromStorage = localStorage.getItem('language');
        return languageFromStorage ? JSON.parse(languageFromStorage) : 'ru';
    } catch (error) {
        console.error('Error getting language from localStorage:', error);
        return 'ua'; 
    }
};

const savedLanguage = getInitialLanguage();

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ru: { translation: ru },
            ua: { translation: ua },
        },
        lng: savedLanguage,  
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
